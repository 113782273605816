"use strict";
import { Observable } from "rxjs";
import { IModuleConfig, IService } from "@tci/common";

// Global Vars
declare var _: any;
declare var Rx: any;
declare var SystemJS: any;
declare var window;

window.langUrl = [];
window.hybridModuleNames = [];
window.hybridModuleObjects = [];
window.moduleConfigObjs = [];
window.moduleNames = [];
// window.isFlogoE = false;
window.define = SystemJS.amdDefine;
window.require = window.requirejs = SystemJS.amdRequire;

const UI_SERVICES_URL = "/api/v1/uiservices";
const SYSTEMJS_BASE_CONFIG = "systemjs.config.base.js";

export class Bootstrap {
    private static instance: Bootstrap;

    private uiServices = <IModuleConfig[]>[];

    private constructor(
        private wnd: any,
        private lo: any,
        private systemJS: any,
        private rx: any
    ) {}

    public static getInstance(wnd: any, lo: any, systemJS: any, rx: any) {
        if (!Bootstrap.instance) {
            Bootstrap.instance = new Bootstrap(wnd, lo, systemJS, rx);
        }
        return Bootstrap.instance;
    }

    public start(): Observable<any> {
        return this
            .rx
            .Observable
            .forkJoin(
                this.loadAllModuleConfigs(),
                this
                    .rx
                    .Observable
                    .fromPromise(
                        this
                            .systemJS
                            .import(SYSTEMJS_BASE_CONFIG)
                    )
            )
            .map(
                ([
                    allUIServiceConfigs,
                    systemjsConfigBase
                ]) => {
                    const appConfig = this
                        .lo
                        .merge(
                            {},
                            systemjsConfigBase.default,
                            this
                                .lo
                                .chain(allUIServiceConfigs)
                                .filter(uiServiceConfig => uiServiceConfig.config.type === "ng2")
                                .sortBy(uiServiceConfig => uiServiceConfig.config.name === "webserver" ? 1 : 0)
                                .map(uiServiceConfig => this.loadNg2SystemJSConfigs(uiServiceConfig.config))
                                .reduce((accumulator, systemjsConfig) => this.lo.merge(accumulator, systemjsConfig), {})
                                .value()
                        );

                    this
                        .systemJS
                        .config(this.removeTestingDeps(appConfig));

                    return this
                        .rx
                        .Observable
                        .fromPromise(
                            this
                                .systemJS
                                .import("ng2app")

                );
                }
            )
            .do(() => {
                this.systemJS.import("primeng/resources/primeng.min.css");
                this.systemJS.import("primeng/resources/themes/bootstrap/theme.css");
                this.systemJS.import("perfect-scrollbar/css/perfect-scrollbar.css");
            });
    }

    private removeTestingDeps(config: any) {
        this
            .lo
            .forEach(config.map, (value, key) => {
                if (this.lo.includes(key, "testing")) {
                    delete config.map[key];
                }
            });

        return config;
    }

    private loadAllModuleConfigs(): Observable<{ name: string, config: IModuleConfig, basePath: string }[]> {
        return this
            .getServices()
            .mergeMap((services: IService[]) => services)
            .filter((service: IService) => !this.lo.isEmpty(service.moduleConfig))
            .map((service: IService) => {
                // if (service.name === "wistudio") {
                //     this.wnd.isFlogoE = !!service.isDockerDeployment;
                // }
                const moduleConfig = service.moduleConfig;
                /** Hybrid module array to be used where existing
                 * ng1 code can handle both ng1 and ng2 equally
                 * without causing errors, otherwise use existing
                 * arrays
                 * e.g. where menu is enabled/disabled hybrid should be used
                 * tropos.availabvarenant.service.js
                 */
                this.wnd.moduleConfigObjs.push(moduleConfig);
                if (moduleConfig.name === "mg") {
                    this.wnd.moduleNames.push(moduleConfig.name);
                }
                if (this.lo.isArray(moduleConfig.langFile)) {
                    this.lo.forEach(moduleConfig.langFile, (filePath: string) => {
                        if (filePath) {
                            this.loadLocaleFiles(moduleConfig.name, filePath);
                        }
                    });
                }
                return {
                    name: service.name,
                    config: moduleConfig,
                    basePath: service.url as string
                };
            })
            .toArray();
    }

    private getServices(): Observable<IService[]> {
        if (!this.lo.isEmpty(this.uiServices)) {
            return this
                .rx
                .Observable
                .of(this.uiServices);
        } else {
            return this
                .rx
                .Observable
                .ajax
                .getJSON(UI_SERVICES_URL)
                .map(data => {
                    this.uiServices = this.lo.filter(data, value => !(value.isNg2 && value.isLocalTenant));
                    return this.uiServices;
                });
        }
    }

    private loadLocaleFiles(microServiceName: string, path: string): void {
        this
            .wnd
            .langUrl
            .push("/" + (microServiceName === "mg" ? "mgs" : microServiceName) + "/dist/" + path);
    }

    /**
     * Loads Angular 2 SystemJS config from decomposed microservices
     */
    private loadNg2SystemJSConfigs(moduleConfig): any {
        if (
            moduleConfig.routes &&
            this.lo.isArray(moduleConfig.routes) &&
            !this.lo.isEmpty(moduleConfig.routes)
        ) {
            this.wnd.ng2Routes = this.lo.concat(this.wnd.ng2Routes || [], moduleConfig.routes);
        }

        return this.lo.merge({}, moduleConfig.systemjs);
    }
}

(function (wnd, lo, systemJS, rx) {
    "use strict";
    // Set the Global module vars here
    Bootstrap
        .getInstance(wnd, lo, systemJS, rx)
        .start()
        .mergeMap(p => p)
        .subscribe(
            () => {
                console.log("Started");
            },
            error => {
                console.log("Failed to start", error);
            }
        );
})(window, _, SystemJS, Rx);
